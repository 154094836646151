import mainBg from "./mainBg.png";
import ts1 from "./ts1.png";
import ts2 from "./ts2.png";
import ts3 from "./ts3.png";
import ts4 from "./ts4.png";
import ts5 from "./ts5.png";
import ts6 from "./ts6.png";
import ts7 from "./ts7.png";
import noImage from "./noImage.jpg";
import bandicamIcon from "./Bandicam-Icon.png"
import folderIcon from "./Folder-Icon.png"
import ieIcon from "./IE-Icon.png"
import liveIcon from "./Live-Icon.png"
import mahjongIcon from "./Mahjong-Icon.png"
import mapleIcon from "./Maple-Icon.png"
import mineIcon from "./Mine-Icon.png"
import rsIcon from "./RS-Icon.png"
import skypeIcon from "./Skype-Icon.png"
import winrarIcon from "./Winrar-Icon.png"
import tumblrIcon from "./Tumblr-Icon.png"
import contactIcon from "./CONTACTButton.png"
import homeIcon from "./HOMEButton.png"
import faqIcon from "./FAQButton.png"
import footer from "./Footer.png"
import windowLogo from "./Windows.png";
import cart from "./cart.png";
import header from "./UH2 Watermark.svg";
import videoWidget from "./VideoWidget.png";
import galleryWidget from "./GalleryWidget.svg";
import MP3Widget from "./MP3Widget.png";
import sampleVideo from "./sampleVideo.mp4";
import cat from "./cat.jpg";
import AddtoCartButton from "./AddtoCartButton.svg";
import LeftButton from "./LeftButton.png";
import RightButton from "./RightButton.png";
import productContainer from "./ProductContainer.svg";
import winIcons from "./winIcons.png";
import CartCloseIcon from "./CartCloseIcon.png";
import gameIcon from "./Gaming.png";
import networkIcon from "./Connect.png";
import downloadIcon from "./Contacts.png";
import feIcon from "./network.png";
import browserIcon from "./Explorer.png";
import Notepad from "./Notepad.png";
import vistaHomeIcon from "./vistaHomeIcon.png";
import vistaContactIcon from "./contactIcon.png";
import maxIcon from "./maxIcon.png";
import minIcon from "./minIcon.png";
import closeIcon from "./closeIcon.png";

const IMAGES = {
  CLOSE_ICON: closeIcon,
  MAX_ICON: maxIcon,
  MIN_ICON: minIcon,
  VISTA_CONTACT_ICON: vistaContactIcon,
  VISTA_HOME_ICON: vistaHomeIcon,
  NOTE_PAD: Notepad,
  GAME_ICON: gameIcon,
  NETWORK_ICON: networkIcon,
  FE_ICON: feIcon,
  DOWNLOAD_ICON: downloadIcon,
  BROWSER_ICON: browserIcon,
  CART_CLOSE_ICON: CartCloseIcon,
  PRODUCT_CONTAINER: productContainer,
  LEFT_ARROW: LeftButton,
  RIGHT_ARROW: RightButton,
  ADD_TO_CART_BUTTON: AddtoCartButton,
  CAT: cat,
  SAMPLE_VIDEO: sampleVideo,
  NO_IMAGES: noImage,
  MAIN_BG: mainBg,
  TS1: ts1,
  TS2: ts2,
  TS3: ts3,
  TS4: ts4,
  TS5: ts5,
  TS6: ts6,
  TS7: ts7,
  CART: cart,
  BANDICAM: bandicamIcon,
  FOLDER_ICON: folderIcon,
  IE_ICON: ieIcon,
  LIVE_ICON: liveIcon,
  MAHJONG_ICON: mahjongIcon,
  MAPLE_ICON: mapleIcon,
  MINE_ICON: mineIcon,
  RS_ICON: rsIcon,
  SKYPE_ICON: skypeIcon,
  WINRAR_ICON: winrarIcon,
  TUMBLR_ICON: tumblrIcon,
  CONTACT_ICON: contactIcon,
  FAQ_ICON: faqIcon,
  HOME_ICON: homeIcon,
  FOOTER: footer,
  WINDOW_LOGO: windowLogo,
  HEADER: header,
  VIDEO_WIDGET: videoWidget,
  GALLERY_WIDGET: galleryWidget,
  MP3_WIDGET: MP3Widget,
  WIN_ICON: winIcons,
};
export default IMAGES;
