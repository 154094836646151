/* eslint-disable */
import PropTypes from "prop-types";
import { Card, Col, Row, Form, Button, Modal, Input } from "antd";
import TextArea from "antd/lib/input/TextArea";
import IMAGES from "../../assets/images";

function ContactModal(props) {
  const { open, setOpen } = props;
  const onFinish = (values) => {
    console.log("Success:", values);
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <Modal
      // centered
      // maskClosable
      open={open}
      //   onOk={() => setOpen(false)}
      //   onCancel={() => setOpen(false)}
      width={700}
      closable={false}
      footer={null}
      style={{
        top: 40,
      }}
    >
      <Card className="glassy-card mb-0">
        <div
          className="d-flex align-items-start justify-content-between"
          style={{ padding: "0 0.5rem" }}
        >
          <div className="fs-xs fw-normal py-1 text-black">Contact</div>
          <img
            src={IMAGES.CART_CLOSE_ICON}
            alt="cart close icon"
            width="50px"
            onClick={() => setOpen(false)}
            className="cursor-pointer cross-icon"
          />
        </div>
        <div className="bg-white p-3 mb-2">
          <h3 className="fw-normal text-blue w-75 contact-title">
            Contact me about anything order related and I will get to you as
            soon as possible!
          </h3>
          <Form
            name="basic"
            initialValues={{
              remember: true,
            }}
            layout="vertical"
            requiredMark={false}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Row gutter={12}>
              <Col xs={24} md={7}>
                <Form.Item
                  label=<h5 className="fw-normal mb-0 text-black">Name</h5>
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: "Please input your username!",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} md={7}>
                <Form.Item
                  label=<h5 className="fw-normal mb-0 text-black">
                    Order Number
                  </h5>
                  name="orderNumber"
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} md={14}>
                <Form.Item
                  label=<h5 className="fw-normal mb-0 text-black">Email *</h5>
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Please input your email!",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} md={24}>
                <Form.Item
                  label=<h5 className="fw-normal mb-0 text-black">Message *</h5>
                  name="message"
                  rules={[
                    {
                      required: true,
                      message: "Please input your message!",
                    },
                  ]}
                >
                  <TextArea rows={5} />
                </Form.Item>
              </Col>
              <Col xs={24} className="d-flex justify-content-center">
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="submit-btn-solid"
                  >
                    Submit
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </Card>
    </Modal>
  );
}

ContactModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};
export default ContactModal;
